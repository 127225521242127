import { Global, css } from '@emotion/react';
import { useSpring, animated, SpringValue } from '@react-spring/web';
import { getMobileOperatingSystem, iOSVersion } from '../../../../util/detect';
import { getScrollbarWidth, hasVScroll as getHasVScroll } from './layout';
import {
  ReactNode,
  useCallback,
  useEffect,
  useState,
  memo,
  CSSProperties,
} from 'react';
import dynamic from 'next/dynamic';
import { zIndices } from '../../../../constants/z-indices';

const Portal = dynamic(() => import('./portal'));

const modalZIndex = zIndices.MODALS;

function freezeVp(e: Event) {
  e.preventDefault();
}

const isHTMLElement = (element: Element): element is HTMLElement =>
  typeof (element as HTMLElement).focus !== 'undefined';

interface SharedProps {
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'full';
  className?: string;
  autoFocus?: boolean;
  close?: () => void;
  children: ReactNode;
  width?: string;
  maxWidth?: string;
  borderRadius?: string;
  fixToBottomOnMobileAndTablet?: boolean;
  overlayStyles?: CSSProperties;
}

type ModalWindowProps = SharedProps & {
  top: SpringValue<number>;
  bottom: SpringValue<number>;
};

const modalWidthFromSize = (
  size: 'tiny' | 'small' | 'medium' | 'large' | 'full'
) => {
  let modalWidth: string | undefined;

  switch (size) {
    case 'tiny':
      modalWidth = '30%';
      break;
    case 'small':
      modalWidth = '50%';
      break;
    case 'medium':
      modalWidth = '70%';
      break;
    case 'large':
      modalWidth = '90%';
      break;
    default:
      modalWidth = '100%';
  }

  return modalWidth;
};

let modalsOpen = 0;
let prevScrollPos = 0;

const DEFAULT_TOP = 0;

const ModalWindow = memo(
  ({
    size = 'full',
    className,
    children,
    autoFocus,
    top: topProp,
    bottom: bottomProp,
    close: closePropCallback,
    width,
    maxWidth,
    borderRadius,
    fixToBottomOnMobileAndTablet,
  }: ModalWindowProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isIPhone, setIsIPhone] = useState(false);
    const [needsResize, setNeedsResize] = useState(false);
    const [needToHideRoot, setNeedToHideRoot] = useState(false);

    const [modalDiv, setModalDiv] = useState<HTMLDivElement | null>(null);
    const [
      focusedElBeforeOpen,
      setFocusedElBeforeOpen,
    ] = useState<Element | null>(null);

    const [style, setStyle] = useState<{ top: number; opacity?: number }>({
      top: DEFAULT_TOP,
      opacity: 0,
    });

    const widthFromSize = modalWidthFromSize(size);
    const modalWidth = width || widthFromSize;

    const refCallback = (node: HTMLDivElement | null) =>
      node && setModalDiv(node);

    const open = useCallback(() => {
      if (modalsOpen === 0) {
        const hasVScroll = getHasVScroll();

        if (isIPhone) {
          prevScrollPos = document.documentElement.scrollTop;
          document.body.style.overflow = 'hidden';
          document.body.style.height = '100%';
          document.body.style.position = 'fixed';
          document.body.addEventListener('touchmove', freezeVp, false);

          const root = document.getElementById('root');
          if (needToHideRoot && root) {
            root.style.display = 'none';
            document.body.style.position = '';
          }
        }

        document.body.classList.add('is-modal-open');
        if (hasVScroll) {
          document.documentElement.style.paddingRight = `${getScrollbarWidth()}px`;
        }
      }

      modalsOpen++;

      setIsOpen(true);
    }, [isIPhone, needToHideRoot]);

    const close = useCallback(() => {
      if (modalsOpen === 1) {
        document.body.classList.remove('is-modal-open');
        document.documentElement.style.paddingRight = '';

        if (isIPhone) {
          document.body.style.overflow = '';
          document.body.style.height = '';
          document.body.style.position = '';
          document.body.removeEventListener('touchmove', freezeVp, false);

          const root = document.getElementById('root');
          if (needToHideRoot && root) {
            root.style.display = '';
          }

          document.documentElement.scrollTop = prevScrollPos;
        }
      }

      modalsOpen--;

      // reset element focus
      if (focusedElBeforeOpen) {
        isHTMLElement(focusedElBeforeOpen) && focusedElBeforeOpen.focus();
        setFocusedElBeforeOpen(null);
      }

      setIsOpen(false);
    }, [isIPhone, needToHideRoot, focusedElBeforeOpen]);

    const resize = useCallback(() => {
      if (!modalDiv) {
        return;
      }

      const windowHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      );

      let top = 0;

      if (modalDiv.clientHeight < windowHeight) {
        const diff = windowHeight - modalDiv.clientHeight;
        top = diff / 2;
      }

      top = Math.max(top, 8);

      const diff = Math.abs(style.top - top);
      top = diff > 50 ? top : style.top;

      setStyle({ top });
      setNeedsResize(false);
    }, [style, modalDiv]);

    /**
     * Trigger resize callback.
     */
    useEffect(() => {
      needsResize && resize();
    }, [needsResize, resize]);

    /**
     * Listen for modal resize events.
     */
    useEffect(() => {
      if (modalDiv) {
        const observer = new ResizeObserver(_ => setNeedsResize(true));
        observer.observe(modalDiv);
        return () => observer.disconnect();
      }
    }, [modalDiv]);

    /**
     * Device info.
     */
    useEffect(() => {
      const iOSV = iOSVersion();
      setNeedToHideRoot(!!iOSV && iOSV >= 11 && iOSV < 11.3);
      setIsIPhone(getMobileOperatingSystem() === 'iOS');
    }, []);

    /**
     * Previous element.
     */
    useEffect(() => {
      !focusedElBeforeOpen && setFocusedElBeforeOpen(document.activeElement);
    }, [focusedElBeforeOpen]);

    /**
     * Focus into modal.
     */
    useEffect(() => {
      if (isOpen && autoFocus && modalDiv) {
        const focusableEl = modalDiv.querySelector(
          'a[href], area[href], input:not([disabled]), select:not([disabled]),' +
            'textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
        );
        focusableEl && isHTMLElement(focusableEl) && focusableEl.focus();
      }
    }, [isOpen, autoFocus, modalDiv]);

    /**
     * Open modal on initial render.
     */
    useEffect(() => {
      !isOpen && open();
    }, [isOpen, open]);

    /**
     * Close modal in use effect dismount.
     */
    useEffect(() => {
      isOpen && (() => close());
    }, [isOpen, close]);

    return (
      <animated.div
        role="dialog"
        aria-modal="true"
        ref={refCallback}
        onKeyDown={e =>
          e.code === 'Escape' && closePropCallback && closePropCallback()
        }
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex, jsx-a11y/tabindex-no-positive
        tabIndex={1}
        className={`modal ${className || ''}`}
        style={{
          display: 'block',
          ...style,
          ...(topProp
            ? {
                top: topProp.to(top => style.top + Number(top || 0)),
              }
            : {}),
          ...(bottomProp
            ? {
                bottom: bottomProp.to(bottom => Number(bottom || 0)),
              }
            : {}),
        }}
        css={theme => css`
          [data-whatinput='mouse'] & {
            outline: 0;
          }

          z-index: ${modalZIndex + 1};
          /* Workaround android browser z-index bug */
          backface-visibility: hidden;

          display: none;
          padding: 0;

          border: 0;
          background-color: white;

          outline: none;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
            0 1px 2px rgba(0, 0, 0, 0.24);

          overflow-y: visible;

          height: auto;

          /* Make sure rows don't have a min-width on them */
          .column {
            min-width: 0;
          }

          /* Strip margins from the last item in the modal */
          > :last-child {
            margin-bottom: 0;
          }

          @media ${theme.mediaQueries.tabletUp} {
            border-radius: ${borderRadius || `${theme.radii[2]}px`};
            min-height: 0;
            right: auto;
            left: auto;
            margin: 0 auto;
            width: ${modalWidth};
            max-width: ${maxWidth || `${theme.maxGridWidth}px`};
          }

          position: relative;
          top: 100px;
          margin-right: auto;
          margin-left: auto;

          &.collapse {
            padding: 0;
          }

          ${size === 'full' &&
          css`
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            width: 100%;
            max-width: none;
            height: 100%;
            min-height: 100%;
            margin-left: 0;

            border: 0;
            border-radius: 0;
          `}

          ${fixToBottomOnMobileAndTablet
            ? css`
                @media ${theme.mediaQueries.tabletDown} {
                  top: initial !important;
                  will-change: bottom;
                  position: absolute;
                  width: 100%;
                  max-width: initial;
                  max-height: 85vh;
                  max-height: 90dvh;
                  overflow-y: scroll;
                  border-top-left-radius: 10px;
                  border-top-right-radius: 10px;
                }
                @media ${theme.mediaQueries.desktopUp} {
                  bottom: initial !important;
                  will-change: top;
                }
              `
            : css`
                bottom: initial !important;
                will-change: top;
              `}

          .header {
            margin-bottom: ${theme.space[3]};
            color: ${theme.colors.black};
            min-height: 50px;

            @media ${theme.mediaQueries.tabletUp} {
              border-top-right-radius: ${theme.radii[2]};
              border-top-left-radius: ${theme.radii[2]};
            }

            h1 {
              padding: 0.5rem 0;
              font-size: 1.3em;
              margin-bottom: 0;
            }

            a.close {
              color: white;
            }
          }

          &.secondary .header {
            background: linear-gradient(#fdfefe, #f2f2f4);
            border-bottom: 1px solid ${theme.colors.black};

            color: ${theme.colors.black};

            a.close {
              color: ${theme.colors.black};
            }
          }

          &.alert .header {
            background-color: ${theme.colors.pink};
            border-bottom: 1px solid ${theme.colors.black};
          }

          &.tab-modal {
            .header {
              flex-wrap: nowrap;
            }
            .bkm-tabs {
              .bkm-tab:first-of-type {
                margin-left: auto;
              }
            }
          }
        `}
      >
        {children}
      </animated.div>
    );
  }
);

type ModalComponentProps = SharedProps & {
  isOpen: boolean;
};

const Modal = memo(
  ({
    autoFocus = true,
    isOpen,
    close,
    children,
    overlayStyles,
    ...rest
  }: ModalComponentProps) => {
    const [shouldClose, setShouldClose] = useState(false);
    const [needToHideRoot, setNeedToHideRoot] = useState(false);

    const [{ opacity, top, bottom }, animate] = useSpring(
      {
        opacity: 0,
        top: 10,
        bottom: -50,
      },
      []
    );

    const closeModal = useCallback(() => {
      if (close) {
        close();
        setShouldClose(false);
      }
    }, [close]);

    useEffect(() => {
      const iOSV = iOSVersion();
      setNeedToHideRoot(!!iOSV && iOSV >= 11 && iOSV < 11.3);
    }, []);

    /**
     * Begin animation/spring with a short delay after opening.
     */
    useEffect(() => {
      animate.start({
        opacity: isOpen ? 1 : 0,
        top: isOpen ? 0 : 10,
        bottom: isOpen ? 0 : -50,
      });
    }, [isOpen, animate]);

    if (!isOpen) {
      return null;
    }

    return (
      <animated.div
        className="modal-overlay"
        role="dialog"
        onClick={event =>
          shouldClose && event.currentTarget === event.target && closeModal()
        }
        onMouseDown={event =>
          event.currentTarget === event.target && setShouldClose(true)
        }
        onKeyUp={event => event.currentTarget === event.target && closeModal()}
        style={{
          opacity,
          ...(needToHideRoot
            ? {
                position: 'absolute',
                width: '100vw',
              }
            : {}),
          ...overlayStyles,
        }}
        css={css`
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: ${modalZIndex};

          background-color: rgba(0, 0, 0, 0.45);

          -webkit-overflow-scrolling: touch;
          overflow-y: auto !important;
        `}
      >
        <ModalWindow
          {...rest}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          close={close}
          top={top}
          bottom={bottom}
        >
          {children}
        </ModalWindow>

        <Global
          styles={css`
            /* Disables the scroll when Reveal is shown to prevent the background from shifting */
            html.is-modal-open {
              width: 100%;
              overflow: hidden;
              position: relative;

              body {
                overflow-y: hidden;
              }
            }

            body.is-modal-open {
              overflow: hidden;
              position: relative;
              width: 100%;
            }
          `}
        />
      </animated.div>
    );
  }
);

export type ModalProps = ModalComponentProps & {
  shouldPortal?: boolean;
};

const Wrapper = ({ shouldPortal, ...restProps }: ModalProps) => {
  if (shouldPortal) {
    return (
      <Portal>
        <Modal {...restProps} />
      </Portal>
    );
  }

  return <Modal {...restProps} />;
};

export default Wrapper;
