import { Box, Flex } from 'components/box';
import Modal, { ModalProps } from '.';
import Button from 'components/button';
import { X as XIcon } from 'react-feather';
import { css } from '@emotion/react';
import { Heading } from 'components/typography';

type SpacingValue = string | number | null;

const ModalWithClose = ({
  title,
  color = 'black',
  children,
  close,
  iconColor,
  iconSize,
  padding = 3,
  removeContentPadding,
  ...restProps
}: ModalProps & {
  title?: string;
  color?: string;
  iconColor?: string;
  iconSize?: number;
  padding?: SpacingValue | SpacingValue[];
  removeContentPadding?: boolean;
}) => (
  <Modal {...restProps} close={close}>
    {title ? (
      <>
        <Flex
          backgroundColor={color}
          p={3}
          justifyContent="space-between"
          borderTopLeftRadius={[null, 1]}
          borderTopRightRadius={[null, 1]}
        >
          <Heading color="white" my="auto">
            {title}
          </Heading>

          <Button variant="flat" onClick={close} p={0}>
            <XIcon
              css={css`
                color: white;
              `}
            />
          </Button>
        </Flex>
        <Box p={removeContentPadding ? 0 : 3}>{children}</Box>
      </>
    ) : (
      <Box p={padding}>
        <Button
          variant="flat"
          onClick={close}
          css={css`
            position: absolute;
            top: 0;
            right: 0;
            z-index: 10;
          `}
        >
          <XIcon
            css={theme => css`
              color: ${iconColor
                ? theme.colors[iconColor]
                : theme.colors.darkGrey};
            `}
            size={iconSize || 30}
          />
        </Button>

        <Box
          pt={removeContentPadding ? 0 : [1, 2]}
          pb={removeContentPadding ? 0 : [0, null, 2]}
          mx={[null, null, removeContentPadding ? 0 : 1]}
        >
          {children}
        </Box>
      </Box>
    )}
  </Modal>
);

export default ModalWithClose;
